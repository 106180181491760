html {
    overflow-y:scroll;
}

.shadow {
    box-shadow: 0 0 10px rgba(0,0,0,0.5); 
}

.pointer{
	cursor: pointer;
}

.aslink:hover {
    text-decoration: underline;
}